import {
    CloseCircleOutlined,
    ExclamationCircleFilled,
} from "@ant-design/icons";
import {Button, Form, Input, Modal, Select} from "antd";
import {FunctionComponent, useState} from "react";
import {useQuery} from "react-query";
import {useNavigate} from "react-router-dom";
import {UserWithRoles} from "../../../Api";
import useUserAction from "../../../actions/useUserAction";
import {api} from "../../../api_client";
import FormHeader from "../../../shared/FormHeader";
import UserChangePassword from "./UserChangePassword";
import {clearDisabledValue} from "../../../shared/clearFormTag";

const UserForm: FunctionComponent<{
    user: UserWithRoles;
    onDelete: () => void;
    isNew: boolean;
}> = (props) => {
    const {
        data: roles,
        error: rolesError,
        isLoading: rolesLoading,
    } = useQuery({
        queryKey: [`roles`],
        queryFn: () => api.helpers.rolesList(),
    });
    const [form] = Form.useForm();
    const {updateUser, userLoading} = useUserAction({id: props.user.id});
    const navigate = useNavigate();

    const onFinishFailed = (errorInfo: any) => {
        console.log("Failed:", errorInfo);
    };

    const [base64, setBase64] = useState("");
    const [file, setFile] = useState(null);
    const [isOpenChangePass, setChangePass] = useState(false);
    const [pass, setPass] = useState("");

    async function handleUpdate() {
        await form.validateFields();

        let res = {
            ...form.getFieldsValue(),
            roles: form.getFieldsValue().roleIds ?? [],
        };

        if (pass) {
            res.password = pass;
        }

        if (res.email == undefined) {
            delete res.email;
        }
        if (res.email == "") {
            res.email = null;
        }

        if (res.telegram_id == undefined) {
            delete res.telegram_id;
        }

        if (res.telegram_id == "") {
            res.telegram_id = null;
        }

        if (file) {
            res["file"] = file[0];
        }

        if (base64 == "/layout/avatar.png") {
            res["file"] = "";
        }

        delete res.roleIds;

        var form_data = new FormData();

        if (props.user.in_active_directory) {
            form_data.append("telegram_id", res.telegram_id || '');
            for (let role of form.getFieldsValue().roleIds ?? []) {
                form_data.append("roles[]", role);
            }
        } else {
            for (var key in res) {
                form_data.append(key, res[key]);
            }

            for (let role of form.getFieldsValue().roleIds ?? []) {
                form_data.append("roles[]", role);
            }
        }

        if ((form.getFieldsValue().roleIds ?? []).length == 0) {
            form_data.append("roles[]", "");
        }

        form_data.delete('exceeding_limit_percent')

        form_data.append('exceeding_limit_percent', `${(Number(form.getFieldValue('exceeding_limit_percent')) || 0)}`)

        updateUser.mutate({
            id: props.user.id ?? "",
            payload: form_data as any,
            isNew: props.isNew,
            roles: [],
        });
    }

    function handleSelectFile(e: any) {
        setFile(e?.target?.files as any);

        var reader = new FileReader();
        reader.readAsDataURL(e.target?.files?.[0]);
        reader.onload = function () {
            setBase64(reader.result as any);
        };
        reader.onerror = function (error) {
            console.log("Error: ", error);
        };
    }

    function blocUser() {
        Modal.confirm({
            title: "Заблокировать пользователя?",
            icon: <ExclamationCircleFilled/>,
            cancelText: "Нет",
            okText: "Да",

            cancelButtonProps: {type: "text"},
            okButtonProps: {
                type: "primary",
                style: {backgroundColor: "#F09F54"},
            },
            onOk() {
                updateUserAction("archive");
            },
            onCancel() {
                console.log("Cancel");
            },
        });
    }

    function updateUserAction(status: "archive" | "active") {
        if (props.user.in_active_directory) {
            updateUser.mutate({
                id: props.user.id ?? "",
                payload: {
                    telegram_id: props.user.telegram_id,
                    status: status,
                } as any,
                isNew: false,
            });
        } else {
            updateUser.mutate({
                id: props.user.id ?? "",
                payload: {
                    full_name: props.user.full_name ?? "",
                    login: props.user.login ?? "",
                    email: props.user.email ?? null,
                    telegram_id: props.user.telegram_id,
                    status: status,
                } as any,
                isNew: props.isNew,
                roles: form.getFieldsValue().roleIds ?? [],
            });
        }
    }

    return (
        <div>
            <FormHeader
                title={props.user.full_name ?? "Добавление пользователя"}
                tag={props.user.in_active_directory ? "LDAP" : ""}
                onSubmit={handleUpdate}
                onCancel={() =>
                    props.isNew ? navigate(`/users`) : navigate(`/users/${props.user.id}`)
                }
            />

            <Form
                form={form}
                name="basic"
                layout={"vertical"}
                initialValues={{
                    ...props.user,
                    roleIds: props.user.roles?.map((e) => e.id) || [],
                    baseRoleIds: props.user.base_roles?.map((e) => e.id) || [],
                }}
                onFinish={handleUpdate}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                disabled={rolesLoading || userLoading}
            >
                <div className={"FormUserLayout"}>
                    <div>
                        <Form.Item<UserWithRoles> label="Аватар">
                            {((!base64 && props.user.avatar_url && !props.user.in_active_directory) ||
                                (base64 && base64 != "/layout/avatar.png")) && (
                                <CloseCircleOutlined
                                    style={{
                                        position: "absolute",
                                        marginLeft: "70px",
                                        marginTop: "10px",
                                        color: "white",
                                        cursor: "pointer",
                                        fontSize: "20px",
                                    }}
                                    onClick={() => {
                                        setBase64("/layout/avatar.png");
                                        return;
                                    }}
                                />
                            )}
                            <div
                                className={"img"}
                                style={{
                                    backgroundImage: `url(${
                                        base64 || props.user.avatar_url || "/layout/avatar.png"
                                    })`,
                                    cursor:  props.user.in_active_directory ? "default" : "pointer",
                                }}
                                onClick={() =>
                                    !props.user.in_active_directory ? (document.querySelector("input[type=file]") as any)?.click() : undefined
                                }
                            />
                            <Input type={"file"} onChange={handleSelectFile}/>
                        </Form.Item>
                    </div>
                    <div>
                        <div
                            className={"form_row_inline"}
                            style={{gridTemplateColumns: "1fr 1fr 160px"}}
                        >
                            <Form.Item<UserWithRoles>
                                label="ФИО"
                                name="full_name"
                                rules={[
                                    {
                                        required: true,
                                        message: "Поле обязательно для заполнения",
                                    },
                                ]}
                            >
                                <Input disabled={props.user.in_active_directory}/>
                            </Form.Item>

                            <Form.Item<UserWithRoles>
                                label="Логин"
                                name="login"
                                rules={[
                                    {
                                        required: true,
                                        message: "Поле обязательно для заполнения",
                                    },
                                ]}
                            >
                                <Input disabled={props.user.in_active_directory}/>
                            </Form.Item>

                            {props.user.status == "active" && (
                                <Button danger onClick={blocUser}>
                                    Заблокировать
                                </Button>
                            )}
                            {props.user.status == "archive" && (
                                <Button danger onClick={() => updateUserAction("active")}>
                                    Восстановить
                                </Button>
                            )}
                        </div>
                        <div
                            className={"form_row_inline"}
                            style={{gridTemplateColumns: "2fr 1fr 1fr 160px"}}
                        >
                            <Form.Item<UserWithRoles>
                                label="E-mail"
                                name="email"
                                rules={[
                                    {
                                        type: "email",
                                        message:
                                            "Email не корректен. Email должен быть записан, как login@email.com или как login@email.ru",
                                    },
                                ]}
                            >
                                <Input disabled={props.user.in_active_directory}/>
                            </Form.Item>

                            <Form.Item<UserWithRoles> label="Telegram ID" name="telegram_id">
                                <Input/>
                            </Form.Item>

                            <Form.Item<UserWithRoles> label="Процент превышения" name="exceeding_limit_percent"
                                                      rules={[
                                                          ({getFieldValue}) => ({
                                                              validator(_, value) {
                                                                  const val = getFieldValue('exceeding_limit_percent')
                                                                  if (!val) {
                                                                      return Promise.resolve();
                                                                  }

                                                                  if (`${val}`.includes(',')) {
                                                                      return Promise.reject(new Error('Должно быть числом через "."'));
                                                                  }
                                                                  if (Number.isNaN(Number(val) )) {
                                                                      return Promise.reject(new Error('Должно быть положительным числом'));
                                                                  }
                                                                  if ((Number(val) || 0) >= 0) {
                                                                      return Promise.resolve();
                                                                  }
                                                                  return Promise.reject(new Error('Должно быть положительным числом'));
                                                              },
                                                          }),
                                                      ]}
                            >
                                <Input/>
                            </Form.Item>

                            <Button
                                onClick={() => setChangePass(true)}
                                disabled={props.user.in_active_directory}
                            >
                                Изменить пароль
                            </Button>
                        </div>
                    </div>
                </div>

                <Form.Item<{ roleIds: string[] }> label="Роли" name="roleIds">
                    <Select
                        mode="multiple"
                        filterOption={function (inputValue, option) {
                            if (
                                `${option?.label} ${option?.value}`
                                    ?.toString()
                                    ?.toLowerCase()
                                    .includes(inputValue.toLowerCase())
                            ) {
                                return true;
                            }
                            return false;
                        }}
                    >
                        {(roles?.data ?? []).map((role) => (
                            <Select.Option
                                key={role.value}
                                value={role.value}
                                disabled={role.disabled}
                                label={role.text}
                            >
                <span
                    onClick={() =>
                        clearDisabledValue("roleIds", form, role.value)
                    }
                >
                  {role.text}
                </span>
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item<{ baseRoleIds: string[] }>
                    label="Базовые роли"
                    name="baseRoleIds"
                >
                    <Select
                        mode="multiple"
                        filterOption={function (inputValue, option) {
                            if (
                                `${option?.label} ${option?.value}`
                                    ?.toString()
                                    ?.toLowerCase()
                                    .includes(inputValue.toLowerCase())
                            ) {
                                return true;
                            }
                            return false;
                        }}
                        disabled
                    >
                        {(roles?.data ?? []).map((role) => (
                            <Select.Option
                                key={role.value}
                                value={role.value}
                                disabled={role.disabled}
                                label={role.text}
                            >
                <span
                    onClick={() =>
                        clearDisabledValue("roleIds", form, role.value)
                    }
                >
                  {role.text}
                </span>
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <UserChangePassword
                    isOpen={isOpenChangePass}
                    onUpdate={(pass2) => {
                        setPass(pass2);
                        setChangePass(false);
                    }}
                    onCancel={() => setChangePass(false)}
                />

                {/*<Form.Item wrapperCol={{offset: 8, span: 16}}>*/}
                {/*    <div style={{display: 'flex'}}>*/}
                {/*        {!props.isNew && <Button type="default" style={{marginLeft: 'auto'}}*/}
                {/*                                 onClick={() => showDeleteConfirm(() => deleteUser.mutate([props.user.id ?? '']))}>Удалить</Button>}*/}
                {/*    </div>*/}
                {/*</Form.Item>*/}
            </Form>
        </div>
    );
};

export default UserForm;
